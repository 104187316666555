.eventsUsers {
  box-sizing: border-box;
  padding: 10px;
}

.edit {
  height: 70vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.heading {
  margin: 0px;
}

.input {
  width: 100%;
  margin: 2px;
  border: none;
  border-bottom: 1px solid var(--primaryColor);
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
  height: 25px;
  /* background-color: rgb(230, 223, 252); */
}
.input:focus {
  box-shadow: none;
  /* background-color: white; */
  border-bottom: 2px solid var(--primaryColor);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.title {
  /* float: left; */
  border-bottom: 1px solid var(--primaryColor);
  display: flex;
  justify-content: space-between;
}
.inputField {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  width: 40%;
}

.field {
  color: black;
  font-size: 12px;
  display: block;
}
.member {
  border-bottom: 1px solid grey;
  margin: 5px;
  width: 40%;
}
