@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
}

body {
  margin: 0;
}
