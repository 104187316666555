.webContents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.addBtn {
  margin: 10px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  width: 200px;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
