.contentCard {
  margin: 10px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  width: 200px;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.contentName {
  width: 180px;
  height: 20px;
  text-align: center;
  color: var(--primaryColor);
  font-weight: 900;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.newEntry {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}
