.schedule {
  box-sizing: border-box;
  padding: 10px;
}

.addSchedule {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px;
}

/* input,
textarea {
  width: 200px;
  margin: 10px;
} */

.img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--primaryColor);
}

input[type="datetime-local"] {
  width: fit-content;
}

.desc {
  width: 400px;
  max-width: 200px;
  max-height: 200px;
  overflow: auto;
}
