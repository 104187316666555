.content {
  box-sizing: border-box;
  padding: 10px;
}

.tableWrapper {
  width: calc(100vw - 120px);
  height: calc(100vh - 150px);
  border: 1px solid var(--primaryColor);
  overflow: auto;
}

.table {
  width: 100%;
}
.check {
  width: 20px;
  height: 20px;
}

.newEntry {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  /* width: 90%; */
}

.newEntry .input {
  width: 200px;
  margin: 10px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--primaryColor);
  outline: none;
}

.content table,
th,
td {
  border-bottom: 1px solid var(--primaryColor);
  border-collapse: collapse;
  text-align: center;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

th {
  color: var(--primaryColor);
}

ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.content li {
  background-color: rgba(0, 0, 255, 0.208);
  margin: 3px;
  padding: 5px;
  width: fit-content;
  border-radius: 20px;
}
/* 
.link a {
  display: block;
  width: 300px;
  overflow: auto;
} */

.multi {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--primaryColor);
}

.textEditor {
  height: 70vh;
  overflow: auto;
}

.editor {
  min-height: 35vh;
  border: 0.5px solid rgb(238, 238, 238);
}

.actions {
  width: 100px;
  display: inline-block;
}

.importExport {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.importExport label {
  color: var(--primaryColor);
  font-weight: 700;
}
